<template>
  <div class="newIndex">
    <Head />
    <Bgnew />
    <Foot />
  </div>
</template>

<script>
import Head from '../modules/Head.vue'
import Foot from '../modules/Foot.vue'
import Bgnew from '../modules/Bgnew'
export default {
  name: 'NewIndex',
  components: { Head, Foot, Bgnew }
}
</script>

<style >
img {
  width: 100%;
}
</style>
