<template>
  <div class="bgNew">
    <div class="bg">
      <p>NEWS</p>
      <p class="new">新闻资讯</p>
    </div>
    <div class="news">
      <div class="news-img">
        <div
          v-for="news in list"
          :key="news.id"
          class="news-content"
          @click="newsContent(news.id)"
        >
          <img :src="news.cover">
          <div class="tit" :style="{ background: news.color }">
            {{ news.tag }}
          </div>
          <div class="content">
            <div class="news-text">{{ news.title }}</div>
          </div>
        </div>
        <div v-if="show" class="more" @click="clickMore">更多动态</div>
      </div>
    </div>
    <ul v-if="isShow" class="noMore">
      没有更多新闻啦
    </ul>
  </div>
</template>

<script>
import { reqGetNewsList, reqGetSetting } from '@/api/news'

export default {
  data() {
    return {
      show: false,
      isShow: false,
      moreData: [],
      id: '',
      list: [],
      loading: false,
      finished: false,
      queryForm: {
        channel: 'gg.com',
        pageSize: 3,
        currentPage: 1,
        hot: 1
      },
      querySetting: {
        key: 'article_type'
      }
    }
  },
  created() {
    this.install()
  },
  methods: {
    async install() {
      this.loading = true
      const res = await reqGetNewsList(this.queryForm)
      const resSetting = await reqGetSetting(this.querySetting)
      const resSettingJson = JSON.parse(resSetting.data)
      var mapList = new Array()
      for (var i = 0; i < resSettingJson.length; i++) {
        if (resSettingJson[i].key === 'gg.com') {
          console.log(resSettingJson[i].value)
          mapList = resSettingJson[i].value
          break
        }
      }
      var newsKindMap = new Map()
      var newsKindColorMap = new Map()
      for (var j = 0; j < mapList.length; j++) {
        newsKindMap.set(mapList[j].value, mapList[j].label)
        if (mapList[j].value === 'news') {
          newsKindColorMap.set(mapList[j].value, '#2394C5')
        } else if (mapList[j].value === 'gameWalkthrough') {
          newsKindColorMap.set(mapList[j].value, '#6A22CD')
        } else if (mapList[j].value === 'announcement') {
          newsKindColorMap.set(mapList[j].value, '#EB3644')
        }
      }
      for (var k = 0; k < res.data.length; k++) {
        if (newsKindMap.has(res.data[k].tag)) {
          res.data[k].color = newsKindColorMap.get(res.data[k].tag)
          res.data[k].tag = newsKindMap.get(res.data[k].tag)
        }
      }
      this.list = [...this.list, ...res.data]
      this.moreData = res.data
      this.queryForm.currentPage = res.currentPage + 1
      this.loading = false
      this.show = true
    },
    clickMore() {
      this.install()
      setTimeout(() => {
        if (this.moreData == '') {
          this.isShow = true
          setTimeout(() => {
            this.isShow = false
          }, 1000)
        }
      }, 50)

      return
    },
    newsContent(id) {
      this.$router.push({
        path: '../newsdetailspage',
        query: { id: id }
      })
    }
  }
}
</script>

<style scoped>
.bgNew{
   padding-top:3.575rem;
}
.bg {
  height: 142.5px;
  background: url('../../assets/image/mobile/index/newbg.png') no-repeat;
  background-size: auto 142.5px;
  background-position-x: 50%;
}
.news {
  width: 100%;
  background: url('../../assets/image/mobile/index/news.png') no-repeat;
  background-size: 100% 100%;
  background-color: #f5f5f5;
}
p:first-child {
  padding-top: 3.9375rem;
  font-size: 2.5rem;
  line-height: 2.9375rem;
  opacity: 0.76;
  color: #fff;
  font-family: Poppons !important;
  margin: 0;
  text-align: center;
}
.new {
  font-size: 1.125rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #fff;
  line-height: 1.3125rem;
  margin: 0;
  text-align: center;
}
.bg p {
  font-weight: bold;
}
.news-img {
  padding: 1.5625rem 1.125rem 1rem;
}
.news-content {
  background-color: #fff;
}
.tit {
  font-size: 0.8125rem;
  width: 2.5rem;
  color: #fef6eb;
  padding: 0.1rem 0.3rem;
  text-align: center;
  margin: 1rem auto 0;
}
.content {
  padding: 0 3.75rem;
  margin: 0 auto;
  color: #000052;
  font-size: 0.9375rem;
  padding: 0.25rem 0 2.25rem 0;
  line-height: 0.9375rem;
}
.news-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  line-height: 1.3125rem;
}
.more {
  width: 9.0625rem;
  color: #ffffff;
  background-color: #e60012;
  border-radius: 1.125rem;
  text-align: center;
  line-height: 1.9375rem;
  margin: 1rem auto 0;
}
.noMore {
  position: fixed;
  top: 16rem;
  left: 7.8rem;
  width: 35vw;
  height: 4vh;
  line-height: 4vh;
  text-align: center;
  border-radius: 0.625rem;
  opacity: 0.8;
  color: #000;
  background-color: #eee;
  z-index: 99;
}
</style>
